import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  region: "us",
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    update: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = uiSlice.actions;

export default uiSlice.reducer;
