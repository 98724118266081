import React from "react";
import { Card, Button, Tag, Space } from "antd";
import { useMutation, useQueryClient } from "react-query";
import axios from "Clients/axios";
import RemoveAlertModal from "./RemoveAlertModal";

import("./style.scss");
const AlertItem = ({ item }) => {
  const qc = useQueryClient();
  const mutation = useMutation(() =>
    axios.patch(
      `/anomaly-dashboard/alerts/${item.id}`,
      { enabled: !item.enabled },
      {
        onSuccess: () => {
          qc.invalidateQueries("alerts");
        },
      }
    )
  );
  const handleEnable = () => mutation.mutate();

  return (
    <>
      <Card
        style={{ marginBottom: 5 }}
        title={item.name}
        extra={
          <Space>
            <Tag color={item.enabled ? "lime" : "red"}>
              {item.enabled ? "Active" : "Paused"}
            </Tag>
            <Tag color="gold">
              threshold: <strong>{item.terms[0].threshold}</strong>
            </Tag>
            <Button onClick={handleEnable} loading={mutation.isLoading}>
              {item.enabled ? "Pause" : "Enable"}
            </Button>
            <RemoveAlertModal id={item.id} />
          </Space>
        }
      >
        <code style={{ fontSize: 11 }}>{item.nrql.query}</code>
      </Card>
    </>
  );
};

export default AlertItem;
