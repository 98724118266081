import React from "react";
import { PageHeader, Menu } from "antd";
import { useHistory, Route } from "react-router-dom";
import MerchantDetailsPage from "./details";

export default function MerchantPage({ match }) {
  const history = useHistory();
  const { merchantId, filter } = match.params;

  const handleClick = ({ key }) =>
    history.push(`/merchants/${merchantId}/${key}`);

  return (
    <>
      <div>
        <Menu onClick={handleClick} activeKey={filter} mode="horizontal">
          <Menu.Item key="tracking">Tracking</Menu.Item>
          <Menu.Item key="order">Order</Menu.Item>
        </Menu>
      </div>
      <PageHeader
        onBack={() => history.replace(`/merchants`)}
        title={merchantId}
        subTitle={`Stats for merchant ${merchantId}`}
      />
      <Route
        path="/merchants/:merchantId/tracking"
        component={(props) => (
          <MerchantDetailsPage {...props} parser="tracking" />
        )}
      />
      <Route
        path="/merchants/:merchantId/order"
        component={(props) => <MerchantDetailsPage {...props} parser="order" />}
      />
    </>
  );
}
