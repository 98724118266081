import React from "react";
import useMerchants from "Hooks/useMerchants";
import PageLoading from "Components/PageLoading";
import { Table, Tag } from "antd";
import {
  compose,
  map,
  flatten,
  groupBy,
  values,
  sortBy,
  uniqBy,
  filter,
  reverse,
} from "lodash/fp";
import { parseDomain } from "parse-domain";
import FuzzySearch from "fuzzy-search";
import MergeMerchantsModal from "Components/MergeMerchantsModal";

const columns = [
  {
    title: "Score",
    dataIndex: "score",
    key: "score",
    width: 60,
    render: (_, x) => {
      const { score } = x;
      const color = React.useMemo(() => {
        if (score >= 1) return "red";
        if (score >= 0.5) return "orange";
        return "green";
      }, [score]);
      return (
        <>
          <Tag color={color}>{score}</Tag>
        </>
      );
    },
  },
  {
    title: "Top level domain",
    dataIndex: "domain",
    key: "domain",
  },

  {
    title: "Appears as",
    dataIndex: "merchantnames",
    colSpan: 1,
    render: (_, x) => {
      return (
        <>
          {x.items.map((item) => (
            <Tag key={item.name}>{item.name}</Tag>
          ))}
        </>
      );
    },
  },
  {
    title: "actions",
    dataIndex: "action",
    render: (_, x) => (
      <>
        <MergeMerchantsModal item={x} />
      </>
    ),
  },
];
export default function MerchantDuplicatesPage() {
  const merchants = useMerchants();
  if (merchants.isLoading && !merchants.data) return <PageLoading />;

  const filtered = compose(
    reverse,
    sortBy((x) => x.score),
    map((x) => {
      const [first] = x;
      const searcher = new FuzzySearch(x, ["name"], {
        caseSensitive: false,
      });
      const score = searcher.search(first.name);

      return {
        name: first.name,
        domain: first.domain,
        items: x,
        score: (score.length / x.length).toFixed(1),
      };
    }),
    filter((x) => x.length > 1),
    map(uniqBy("name")),
    values,
    groupBy("domain"),
    flatten,
    map((x) =>
      x.domains.map((d) => {
        const parsed = parseDomain(d);
        if (parsed.errors) return {};
        const final = `${parsed.domain}.${parsed.topLevelDomains.join(".")}`;
        return { name: x._id, domain: final, original_domain: d };
      })
    )
  )(merchants.data);
  console.log(filtered);

  return (
    <div>
      <h3>Duplicate Merchant Names</h3>
      <p>This page shows duplicate merchant names.</p>
      <Table
        size="small"
        columns={columns}
        dataSource={filtered}
        rowKey="domain"
        pagination={{ defaultPageSize: 20, showSizeChanger: false }}
      />
    </div>
  );
}
