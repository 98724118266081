import React from "react";
import { Card, Row, Col } from "antd";
import AccuracyTable from "Components/AccuracyTable";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import "./style.scss";

export default function MerchantPage({ data, accuracy }) {
  const trackingData = React.useMemo(() => {
    if (!data) return [];
    return data.tracking.map((item) => {
      item.label = moment(item.beginTimeSeconds, "X").format(
        "MM/DD/YYYY HH:mma"
      );
      item.displayLabel = moment(item.beginTimeSeconds, "X").format("HH:mma");
      return item;
    });
  }, [data]);

  return (
    <div style={{ margin: 15 }} className="merchant-details">
      <Row gutter={5} style={{ marginBottom: 5 }}>
        <Col sm={24}>
          <Card title="Total Pubs Sent">
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart data={trackingData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="displayLabel" tick={{ fontSize: 11 }} />
                <YAxis
                  tick={{ fontSize: 11 }}
                  type="number"
                  domain={[0, `dataMax`]}
                />

                <Tooltip
                  formatter={(value, name, props) => {
                    const start = moment(
                      props.payload.beginTimeSeconds,
                      "X"
                    ).format("MM/DD/YYYY HH:mmA");
                    const end = moment(
                      props.payload.endTimeSeconds,
                      "X"
                    ).format("MM/DD/YYYY HH:mmA");
                    return [`${value} ${name}`, `${start} - ${end}`];
                  }}
                />
                <Area
                  type="linear"
                  dataKey="pubs"
                  stroke="#1890ff"
                  fill="#46a6ff"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginBottom: 5 }} gutter={5}>
        <Col xs={24}>
          <AccuracyTable accuracy={accuracy} />
        </Col>
      </Row>
    </div>
  );
}
