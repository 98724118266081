import React from "react";
import ProtectedRoute from "Components/ProtectedRoute";
import MerchantDuplicates from "./merchant-duplicates";

import("./style.scss");
export default function AdminPage() {
  return (
    <div className="admin-page">
      <h2>Maintenance</h2>
      <main>
        <ProtectedRoute
          component={MerchantDuplicates}
          path="/admin/merchants/duplicates"
        />
      </main>
    </div>
  );
}
