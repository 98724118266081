import { useQuery } from "react-query";
import axios from "Clients/axios";
import useUI from "./useUI";

export default function useMerchants() {
  const {
    data: { region },
  } = useUI();
  const merchants = useQuery(["merchants", region], () => {
    return axios.get("/anomaly-dashboard/merchants", { params: { region } });
  });
  return merchants;
}
