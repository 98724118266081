import React from "react";
import { Modal, Button } from "antd";
import { useMutation, useQueryClient } from "react-query";
import axios from "Clients/axios";

const { confirm } = Modal;

export default function RemoveAlertModal({ id }) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    () => axios.delete(`/anomaly-dashboard/alerts/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("alerts");
      },
    }
  );
  const showPromiseConfirm = () => {
    confirm({
      title: "Confirm",
      content: `Do you want to remove this alert?`,
      async onOk() {
        await mutation.mutate();
      },
      onCancel() {},
    });
  };
  return (
    <>
      <Button
        type="danger"
        loading={mutation.isLoading}
        onClick={showPromiseConfirm}
      >
        Delete
      </Button>
    </>
  );
}
