import React from "react";
import { Radio } from "antd";
import useUI from "Hooks/useUI";

export default function RegionSwitch({ style }) {
  const {
    data: { region },
    update,
  } = useUI();

  const handleChange = (payload) => {
    update("region", payload.target.value);
  };
  return (
    <div>
      <Radio.Group
        onChange={handleChange}
        value={region}
        style={{ ...style }}
        size="small"
      >
        <Radio.Button value="us">US</Radio.Button>
        <Radio.Button value="uk">UK</Radio.Button>
      </Radio.Group>
    </div>
  );
}
