import React from "react";
import { Table, Badge, Card } from "antd";
import moment from "moment";
import sId from "shortid";
import "./style.scss";

const renderBool = (x) => (x ? <span>Yes</span> : <Badge count="no" />);
const commonColumns = [
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    render(txt) {
      return moment(txt, "x").format("LLLL");
    },
  },
  {
    title: "Domain",
    dataIndex: "sender_domain",
  },
];

const trackingColumns = [
  {
    title: "Carrier",
    dataIndex: "carriers",
    render: renderBool,
  },
  {
    title: "Tracking Link",
    dataIndex: "is_tracking_links",
    render: renderBool,
  },
  {
    title: "Tracking number",
    dataIndex: "is_tracking_numbers",
    render: renderBool,
  },
];

const orderColumns = [
  {
    title: "Order Number",
    dataIndex: "is_order_number",
    render: renderBool,
  },
  {
    title: "Currenct",
    dataIndex: "currency",
    render: renderBool,
  },
  {
    title: "Order Date",
    dataIndex: "is_order_date",
    render: renderBool,
  },
  {
    title: "Order Total",
    dataIndex: "is_order_total",
    render: renderBool,
  },
];

export default function MerchantLogsTable({ logs, parser = "tracking" }) {
  const columns =
    parser === "tracking"
      ? [...commonColumns, ...trackingColumns]
      : [...commonColumns, ...orderColumns];

  return (
    <div className="merchant-logs">
      <Card title="Logs">
        <Table
          dataSource={logs}
          columns={columns}
          rowKey={() => sId.generate()}
          pagination={{ defaultPageSize: 15, showSizeChanger: false }}
          style={{ margin: 15 }}
        />
      </Card>
    </div>
  );
}
