import { useQuery } from "react-query";
import axios from "Clients/axios";

export default function useAlertTypes() {
  const alertTypes = useQuery(
    `alerts-types`,
    () => axios.get(`/anomaly-dashboard/alerts?alertTypes`),
    {
      placeholderData: [],
    }
  );

  return alertTypes;
}
