import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Tag,
  Divider,
  List,
  Alert,
  Checkbox,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "react-query";

import axios from "Clients/axios";

const MergeMerchantsModal = ({ item }) => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState(item.name);
  const [consent, setConsent] = useState(false);
  const qc = useQueryClient();
  const mutation = useMutation(
    (data) => axios.post("/anomaly-dashboard/merchants/merge", data),
    {
      onSuccess: () => {
        qc.invalidateQueries("merchants");
        setVisible(false);
      },
    }
  );

  const handleSubmit = () => {
    mutation.mutate({
      source_merchant_names: item.items
        .map((x) => x.name)
        .filter((x) => x !== name),
      target_merchant_name: name,
    });
  };
  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        Merge
      </Button>
      <Modal
        title={`Merge ${item.name}`}
        centered
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <div>
          <label>
            Enter desired name <small>(or click on tags below)</small>:
          </label>
          <Input
            placeholder="eg. amazon"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div style={{ marginTop: 5 }}>
          {item.items.map((x) => (
            <Tag
              color={x.name === name ? "green" : null}
              key={x.name}
              style={{ cursor: "pointer" }}
              onClick={() => setName(x.name)}
            >
              {x.name}
            </Tag>
          ))}
        </div>
        <Divider />
        <p>
          Clicking <b>OK</b> will merge/rename next merchants:
        </p>
        <List
          dataSource={item.items}
          bordered
          renderItem={(x) => {
            if (x.name === name) return null;
            return (
              <List.Item
                key={x.name}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "40%",
                    opacity: 0.5,
                    textDecoration: "line-through",
                  }}
                >
                  {x.name}
                </div>
                <div style={{ width: 50 }}>
                  <RightOutlined />
                </div>
                <span style={{ margin: "0 10px" }} />
                <b>{name}</b>
              </List.Item>
            );
          }}
        />
        <Alert
          type="error"
          showIcon
          message={
            <div>
              <h4>Please confirm to continue</h4>
              <Checkbox onChange={(e) => setConsent(e.target.checked)}>
                I understand that this action is not reversible.
              </Checkbox>
            </div>
          }
          style={{ margin: "15px 0" }}
        />
        <div style={{ marginTop: 10 }}>
          <Button
            block
            type="primary"
            size="large"
            disabled={!consent}
            onClick={handleSubmit}
            loading={mutation.isLoading}
          >
            {mutation.isLoading ? "Merging..." : "Merge"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MergeMerchantsModal;
