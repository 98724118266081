import { useSelector, useDispatch } from "react-redux";
import { update as updateAction } from "Store/ui";

export default function useUI() {
  const data = useSelector((s) => s.ui);
  const dispatch = useDispatch();

  const update = (field, value) => {
    dispatch(updateAction({ field, value }));
  };

  return { data, update };
}
