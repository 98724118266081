import React from "react";
// import { Card, Statistic, Row, Col } from "antd";
import AccuracyTable from "Components/AccuracyTable";

import("./style.scss");
export default function MerchantsSummary({ merchants }) {
  const { accuracy } = merchants.data;

  return (
    <div style={{ margin: 15 }} className="merchants-summary">
      {/* <Row style={{ marginBottom: 5 }} gutter={5}>
        <Col sm={12}>
          <Card>
            <Statistic
              title="Total emails generated"
              value={merchants.data.total_number_of_generated_emails}
            />
          </Card>
        </Col>
        <Col sm={12}>
          <Card>
            <Statistic
              title="Total Number of Merchants"
              value={merchants.data.total_number_of_merchants}
            />
          </Card>
        </Col>
      </Row> */}
      {accuracy && <AccuracyTable accuracy={accuracy} />}
    </div>
  );
}
