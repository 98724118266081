import React, { useState } from "react";
import { useQuery } from "react-query";
import axios from "Clients/axios";
import MerchantDetails from "Components/MerchantDetails";
import PageLoading from "Components/PageLoading";
import AccuracyTable from "Components/AccuracyTable";
import MerchantLogsTable from "Components/MerchantLogsTable";
import useUI from "Hooks/useUI";

export default function MerchantPage({ match, parser }) {
  const { merchantId } = match.params;
  const [timeframe, setTimeframe] = useState("daily");
  const {
    data: { region },
  } = useUI();

  const merchantQuery = () =>
    axios.get(`/anomaly-dashboard/merchants/${merchantId}`, {
      params: { parser, region, timeframe },
    });

  const merchant = useQuery(
    ["merchant", merchantId, parser, region, timeframe],
    merchantQuery
  );
  const accuracy = useQuery(
    ["merchant-accuracy", merchantId, region, timeframe],
    () =>
      axios.get(`/anomaly-dashboard/merchants/${merchantId}/accuracy`, {
        params: { parser, region, timeframe },
      })
  );
  const logs = useQuery(
    ["merchants-logs", merchantId, parser, region, timeframe],
    () => {
      return axios.get(
        `/anomaly-dashboard/merchants/${merchantId}/logs?parser=${parser}`,
        {
          params: { parser, region, timeframe },
        }
      );
    }
  );

  return (
    <>
      {merchant.isLoading ? (
        <PageLoading />
      ) : (
        <>
          {merchant.data && (
            <MerchantDetails
              data={merchant.data}
              merchantId={merchantId}
              parser={parser}
              timeframe={timeframe}
              setTimeframe={setTimeframe}
            />
          )}
        </>
      )}
      {accuracy.data && !merchant.isLoading && (
        <div style={{ margin: 15 }}>
          <AccuracyTable accuracy={accuracy.data} parser={parser} />
        </div>
      )}
      {logs.data && !merchant.isLoading && (
        <MerchantLogsTable logs={logs.data} type={parser} />
      )}
    </>
  );
}
