import React from "react";
import ProtectedRoute from "Components/ProtectedRoute";
import { Route, Switch } from "react-router-dom";
import AppLayout from "Components/AppLayout";
import LoginPage from "./login";
import DashboardPage from "./dashboard";
import MerchantsPage from "./merchants";
import MerchantPage from "./merchants/merchantId";
import MerchantsSummaryPage from "./merchants/summary";
import AlertsPage from "./alerts";
import AdminPage from "./admin";
import AmazonPage from "./merchants/amazon";

export default function Pages() {
  return (
    <>
      <Switch>
        <Route component={LoginPage} path="/login" />
        <AppLayout>
          <ProtectedRoute component={DashboardPage} exact path="/" />
          <ProtectedRoute
            component={MerchantsSummaryPage}
            exact
            path="/summary"
          />
          <ProtectedRoute
            component={AlertsPage}
            exact
            path="/alerts/:alertType"
          />
          <ProtectedRoute component={MerchantsPage} exact path="/merchants" />
          <Switch>
            <ProtectedRoute
              component={AmazonPage}
              exact
              path="/merchants/amazon"
            />
            <ProtectedRoute
              component={MerchantPage}
              path="/merchants/:merchantId/:filter?"
            />
          </Switch>

          <ProtectedRoute component={AdminPage} path="/admin" />
        </AppLayout>
      </Switch>
    </>
  );
}
