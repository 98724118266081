import React from "react";
import { Form, Input, Button, Select } from "antd";

const { Option } = Select;
export default function MerchantFilters({ onChange }) {
  return (
    <>
      <Form layout="inline" style={{ marginBottom: 30 }} onFinish={onChange}>
        <Form.Item label="Merchant Name" name="name">
          <Input placeholder="eg. apple" />
        </Form.Item>
        <Form.Item label="Domain" name="domain">
          <Input placeholder="eg. amazon.com" />
        </Form.Item>
        <Form.Item label="Country" name="country">
          <Input placeholder="eg. USA" />
        </Form.Item>
        <Form.Item name="tier">
          <Select placeholder="Tier">
            <Option value={null}>All Tiers</Option>
            <Option value="A">Tier A</Option>
            <Option value="B">Tier B</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary">
            Filter Data
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
