import React from "react";
import Pages from "Pages";
import useUser from "Hooks/useUser";
import { ReactQueryDevtools } from "react-query/devtools";

function App() {
  const { isReady } = useUser();

  if (!isReady) return null;
  return (
    <>
      <Pages />
      <ReactQueryDevtools />
    </>
  );
}

export default App;
