import React, { useState } from "react";
import { Card, Row, Col, Radio } from "antd";
import Chart from "./Chart";
import "./style.scss";

export default function MerchantPage({
  data,
  parser,
  setTimeframe,
  timeframe,
}) {
  const [chartType, setChartType] = useState("bar");
  return (
    <div style={{ margin: 15 }} className="merchant-details">
      <Row
        align="end"
        justify="middle"
        style={{ marginTop: -70, marginBottom: 30 }}
      >
        Timeframe:
        <Radio.Group
          value={timeframe}
          style={{ marginLeft: 5, marginRight: 10 }}
          onChange={(e) => setTimeframe(e.target.value)}
        >
          <Radio.Button value="hourly">Last Hour</Radio.Button>
          <Radio.Button value="daily">Today</Radio.Button>
          <Radio.Button value="weekly">Last 7 Days</Radio.Button>
        </Radio.Group>
        Chart Type:
        <Radio.Group
          value={chartType}
          style={{ marginLeft: 5 }}
          onChange={(e) => setChartType(e.target.value)}
        >
          <Radio.Button value="bar">Bar</Radio.Button>
          <Radio.Button value="area">Area</Radio.Button>
        </Radio.Group>
      </Row>
      <Row gutter={5} style={{ marginBottom: 5 }}>
        <Col sm={24}>
          <Card title={`Total pubs published (${parser})`}>
            <Chart data={data.total_pubs_sent} type={chartType} />
          </Card>
        </Col>
      </Row>
      <Row gutter={5} style={{ marginBottom: 5 }}>
        <Col sm={24}>
          <Card title={`Total pubs processed (${parser})`}>
            <Chart data={data.total_pubs_processed} type={chartType} />
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={5} style={{ marginBottom: 5 }}>
        <Col sm={24}>
          <Card
            title={`Total pubs published to Klarna in the last day (${parser})`}
          >
            <Chart type="bar" data={data.total_pubs_to_klarna_last_day} />
          </Card>
        </Col>
      </Row> */}
    </div>
  );
}
