import React, { useMemo } from "react";
import { Layout, Menu } from "antd";
import useUser from "Hooks/useUser";
import { Link, useLocation } from "react-router-dom";
import useAlertTypes from "Hooks/useAlertTypes";
import RegionSwitch from "Components/RegionSwitch";
import {
  AlertOutlined,
  DatabaseOutlined,
  AppstoreOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import("./style.scss");
const { ItemGroup, SubMenu } = Menu;

const { Sider, Content } = Layout;
// const { SubMenu } = Menu;

// const AmazonIcon = () => (
//   <img
//     style={{ width: 16, marginRight: 10, borderRadius: 30, opacity: 0.8 }}
//     src="https://iconarchive.com/download/i54019/danleech/simple/amazon.ico"
//     alt=""
//   />
// );

export default function AppLayout({ children }) {
  const { user, logout } = useUser();
  const { pathname } = useLocation();

  const isAdmin = user && user.role === "admin";
  const isEscalation = user && user.role === "anomaly-dashboard:escalation";

  const { data: alertTypes } = useAlertTypes();

  const active = useMemo(() => {
    if (pathname === "/summary") return ["merchants-summary"];
    if (pathname === "/merchants/amazon") return ["amazon"];
    if (pathname.startsWith("/merchants")) return ["merchants"];

    if (pathname.startsWith("/admin")) return ["admin"];

    return [pathname];
  }, [pathname]);

  return (
    <Layout style={{ minHeight: "100vh" }} className="app-layout">
      <Sider theme="dark" className="sidemenu">
        <div className="logo">
          <img src="/img/nylas_logo_white.svg" alt="" />
        </div>
        <Menu mode="inline" theme="dark" selectedKeys={active}>
          <ItemGroup title="Region" style={{ marginLeft: 15 }}>
            <RegionSwitch />
          </ItemGroup>
          <ItemGroup key="merchants" title="All Merchants">
            <Menu.Item key="merchants" icon={<DatabaseOutlined />}>
              <Link to="/merchants">List</Link>
            </Menu.Item>

            {!isEscalation && (
              <Menu.Item key="merchants-summary" icon={<AppstoreOutlined />}>
                <Link to="/summary">Summary</Link>
              </Menu.Item>
            )}
          </ItemGroup>
          {/* <ItemGroup title="Custom Merchants">
            <Menu.Item key="amazon" icon={<AmazonIcon />}>
              <Link to="/merchants/amazon">Amazon</Link>
            </Menu.Item>
          </ItemGroup> */}
          {isAdmin && (
            <>
              <ItemGroup key="admin-tools" title="Admin">
                <SubMenu
                  key="alerts-summary"
                  icon={<AlertOutlined />}
                  title="Alerts"
                >
                  {alertTypes.map((x) => (
                    <Menu.Item key={`/alerts/${x.id}`}>
                      <Link to={`/alerts/${x.id}`}>{x.label}</Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
                <Menu.Item key="admin" icon={<SettingOutlined />}>
                  <Link to="/admin/merchants/duplicates">Maintenance</Link>
                </Menu.Item>
              </ItemGroup>
            </>
          )}
          <Menu.Item key="logout" onClick={logout} icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
      <Content>{children}</Content>
    </Layout>
  );
}
