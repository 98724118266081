import React from "react";
import { PageHeader } from "antd";
import { useQuery } from "react-query";
import axios from "Clients/axios";
import { useHistory } from "react-router-dom";
import PageLoading from "Components/PageLoading";
import AmazonMerchantDetails from "Components/AmazonMerchantDetails";

export default function AmazonPage() {
  const history = useHistory();
  const merchantId = "Amazon";
  const merchant = useQuery(["merchant", "amazon"], () =>
    axios.get(`/anomaly-dashboard/merchants/amazon`)
  );

  if (merchant.isLoading) return <PageLoading />;

  return (
    <>
      <PageHeader
        onBack={() => history.replace(`/merchants`)}
        title={merchantId}
        subTitle="Stats for merchant Amazon"
      />

      <AmazonMerchantDetails />
    </>
  );
}
