import React, { useMemo, useState } from "react";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { uniq } from "lodash";
import { parseDomain } from "parse-domain";
import Filters from "./Filters";

const columns = [
  {
    title: "Name",
    dataIndex: "_id",
    render: (text, record) => {
      const icon = React.useMemo(() => {
        const target = record.domains[0];
        const res = parseDomain(target);
        const final = `${res.domain}.${res.topLevelDomains.join(".")}`;

        return `https://icons.duckduckgo.com/ip2/${final}.ico`;
      }, [record.domains]);
      return (
        <span>
          <img
            src={icon}
            alt=""
            style={{ width: 15, height: 15, marginRight: 15 }}
          />
          <Link to={`/merchants/${record._id}/tracking`}>{record._id}</Link>
        </span>
      );
    },
  },
  {
    title: "Domains",
    dataIndex: "domains",
    render: (_, rec) => {
      const filtered = useMemo(() => uniq(rec.domains), [rec.domains]);
      return filtered.map((item) => (
        <Tag color="green" key={item}>
          {item}
        </Tag>
      ));
    },
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, rec) => {
      return <Link to={`/merchants/${rec._id}/tracking`}>Details</Link>;
    },
  },
];

export default function MerchantsList({ data, loading }) {
  const [filters, setFilters] = useState({});
  const handleFiltersChange = (f) => {
    setFilters(f);
  };
  const filteredData = useMemo(() => {
    let final = data || [];
    console.log({ filters });
    if (filters.tier) {
      final = final.filter((x) => x.tier === filters.tier);
    }
    if (filters.name) {
      final = final.filter((x) => {
        const lower = x._id ? x._id.toLowerCase() : "";
        return lower.includes(filters.name.toLowerCase());
      });
    }
    if (filters.domain) {
      final = final.filter((x) => {
        const domains = x.domains.join("|");
        return domains.includes(filters.domain.toLowerCase());
      });
    }
    if (filters.country) {
      const cntry = filters.country.toLowerCase();
      final = final.filter((x) => {
        const itemCntry = x.country.toLowerCase();
        return itemCntry.startsWith(cntry);
      });
    }
    return final;
  }, [filters, data]);
  return (
    <>
      <Filters onChange={handleFiltersChange} />
      <Table
        size="small"
        pagination={{ defaultPageSize: 25, showSizeChanger: false }}
        loading={loading}
        columns={columns}
        dataSource={filteredData}
        rowKey="id"
      />
    </>
  );
}
