import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import App from "Components/App";
import store from "./store";

// import "antd/dist/antd.css";
import "antd/dist/antd.dark.css";
import "./style.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <StoreProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </StoreProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("app")
);
