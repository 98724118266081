import { useCallback } from "react";
import { useQueryClient, useMutation, useQuery } from "react-query";
import axios from "Clients/axios";

export default function useUser() {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("accessToken");
  const userQuery = useQuery(
    "user",
    () =>
      axios
        .post(`/auth`, { access_token: token, app: "anomaly-dashboard" })
        .then((res) => {
          localStorage.setItem("accessToken", res.access_token);
          return res.user;
        }),
    {
      enabled: !!token,
      onError: () => {
        localStorage.removeItem("accessToken");
      },
    }
  );

  const authMutation = useMutation(
    (payload) => axios.post("/auth", { ...payload, app: "anomaly-dashboard" }),
    {
      onSuccess: (response) => {
        localStorage.setItem("accessToken", response.access_token);
        queryClient.setQueryData("user", response.user);
      },
    }
  );

  const login = useCallback(async (payload) => {
    const data = await authMutation.mutate(payload);
    return data;
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("accessToken");
    queryClient.setQueryData("user", null);
    window.location.reload();
  }, []);

  return {
    user: userQuery.data,
    login,
    logout,
    query: userQuery,
    auth: authMutation,
    isReady: !token || ["success", "error"].includes(userQuery.status),
  };
}
