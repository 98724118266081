import React from "react";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const formatterFn = (value, name, props) => {
  const start = moment(props.payload.beginTimeSeconds, "X").format(
    "MM/DD/YYYY HH:mmA"
  );
  const end = moment(props.payload.endTimeSeconds, "X").format(
    "MM/DD/YYYY HH:mmA"
  );
  return [`${value} ${name}`, `${start} - ${end}`];
};

export default function MerchantPage({ data, type = "area" }) {
  const pubs = React.useMemo(() => {
    if (!data) return [];
    return data.map((item) => {
      item.label = moment(item.beginTimeSeconds, "X").format(
        "MM/DD/YYYY HH:mma"
      );
      item.displayLabel = moment(item.beginTimeSeconds, "X").format("HH:mma");
      return item;
    });
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height={200}>
      <ComposedChart data={pubs}>
        <CartesianGrid strokeDasharray="4 5" stroke="rgba(255,255,255,0.1)" />{" "}
        <XAxis dataKey="displayLabel" tick={{ fontSize: 11 }} />
        <YAxis
          tick={{ fontSize: 11 }}
          type="number"
          width={40}
          domain={[0, `dataMax`]}
        />
        <Tooltip formatter={formatterFn} labelStyle={{ color: "black" }} />
        {type === "area" && (
          <Area
            type="linear"
            dataKey="pubs"
            stroke="var(--nylas-dark)"
            fill="var(--nylas)"
          />
        )}
        {type === "bar" && (
          <Bar
            type="linear"
            dataKey="pubs"
            stroke="var(--nylas)"
            fill="var(--nylas)"
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
