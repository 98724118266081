import React from "react";
import { Card, Row, Col, Descriptions } from "antd";
import { get } from "lodash";
import "./style.scss";

const AccuracyGroup = ({ accuracy, target }) => {
  const acc = (str, field = "accuracy") => {
    try {
      const f = get(accuracy, `${str}.${field}`);
      if (!f) return "N/A";
      if (f === "null") return "N/A";
      let no = f * 100;
      no = no.toFixed(1);
      return `${no}%`;
    } catch (e) {
      return "N/A";
    }
  };
  return (
    <>
      <div className="accuracy-group">
        <span>Accuracy</span>
        {acc(target)}
      </div>
      {/* <div className="accuracy-group">
        <span>Precision</span>
        {acc(target, "precision")}
      </div> */}
      <div className="accuracy-group">
        <span>Recall</span>
        {acc(target, "recall")}
      </div>
    </>
  );
};

export default function AccuracyTable({ accuracy, parser }) {
  return (
    <Row style={{ marginBottom: 5 }} gutter={5} className="accuracy-table">
      {(!parser || parser === "tracking") && (
        <Col xs={24}>
          <Card title="Tracking accuracy" className="stats-card">
            <Descriptions bordered column={2}>
              <Descriptions.Item label="Tracking number">
                <AccuracyGroup
                  accuracy={accuracy}
                  target="tracking.tracking_numbers"
                />
              </Descriptions.Item>
              <Descriptions.Item label="Tracking Links">
                <AccuracyGroup
                  accuracy={accuracy}
                  target="tracking.tracking_links"
                />
              </Descriptions.Item>
              <Descriptions.Item label="Carrier Name">
                <AccuracyGroup
                  accuracy={accuracy}
                  target="tracking.carrier_name"
                />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      )}
      {(!parser || parser === "order") && (
        <Col xs={24} style={{ marginTop: 15 }}>
          <Card title="Order accuracy" className="stats-card">
            <Descriptions bordered column={2}>
              <Descriptions.Item label="Total Amount">
                <AccuracyGroup accuracy={accuracy} target="order.total" />
              </Descriptions.Item>
              <Descriptions.Item label="Total Taxes">
                <AccuracyGroup accuracy={accuracy} target="order.tax_total" />
              </Descriptions.Item>
              <Descriptions.Item label="Currency">
                <AccuracyGroup accuracy={accuracy} target="order.currency" />
              </Descriptions.Item>
              <Descriptions.Item label="Shipping Total">
                <AccuracyGroup
                  accuracy={accuracy}
                  target="order.shipping_total"
                />
              </Descriptions.Item>
              <Descriptions.Item label="Discount Total">
                <AccuracyGroup
                  accuracy={accuracy}
                  target="order.discount_total"
                />
              </Descriptions.Item>
              <Descriptions.Item label="Order Number">
                <AccuracyGroup accuracy={accuracy} target="order.id" />
              </Descriptions.Item>
              <Descriptions.Item label="Purchase Date">
                <AccuracyGroup
                  accuracy={accuracy}
                  target="order.purchase_date"
                />
              </Descriptions.Item>
              <Descriptions.Item label="Merchant Name">
                <AccuracyGroup accuracy={accuracy} target="merchant.name" />
              </Descriptions.Item>
              <Descriptions.Item label="Merchant Domain">
                <AccuracyGroup accuracy={accuracy} target="merchant.domain" />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      )}
    </Row>
  );
}
