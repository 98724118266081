import React from "react";
import axios from "Clients/axios";
import { useQuery } from "react-query";
import { PageHeader, Row, Col } from "antd";
import Alert from "Components/Alert";
// import CreateAlertModal from "Components/Alert/CreateAlertModal";
import { orderBy } from "lodash";
import PageLoading from "Components/PageLoading";
import useAlertTypes from "Hooks/useAlertTypes";

export default function AlertsPage(props) {
  const { data: alertTypes } = useAlertTypes();
  const { alertType } = props.match.params;
  const alerts = useQuery(["alerts", alertType], () => {
    return axios.get(`/anomaly-dashboard/alerts?alertType=${alertType}`);
  });

  if (alerts.isLoading) return <PageLoading />;
  const sorted = orderBy(alerts.data, ["id"], ["desc"]);

  const type = alertTypes.find((x) => x.id === alertType);
  console.log(type);

  return (
    <div className="nr-alert">
      <Row align="middle">
        <Col sm={12}>
          <PageHeader title="Alerts" subTitle={type ? type.description : ""} />
        </Col>
      </Row>

      <div style={{ padding: 15 }}>
        {sorted.map((item) => (
          <Alert item={item} key={item.id} />
        ))}
      </div>
    </div>
  );
}
