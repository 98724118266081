import React from "react";
import { Redirect, Route } from "react-router-dom";
import useUser from "Hooks/useUser";

export default function ProtectedRoute({ component: Component, ...props }) {
  const { user } = useUser();
  return (
    <Route
      {...props}
      render={(parent) => {
        if (user) {
          return <Component {...parent} />;
        }

        return <Redirect to="/login" />;
      }}
    />
  );
}
