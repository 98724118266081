import React from "react";
import { Card, PageHeader } from "antd";
import MerchantsList from "Components/MerchantsList";
import useMerchants from "Hooks/useMerchants";

export default function Merchantspage() {
  const merchants = useMerchants();
  return (
    <>
      <PageHeader title="Merchants" subTitle="list of all merchants" />
      <Card style={{ margin: 15, marginTop: 0 }}>
        <MerchantsList loading={merchants.isLoading} data={merchants.data} />
      </Card>
    </>
  );
}
