import React from "react";
import { ReactComponent as Logo } from "Assets/logo.svg";
import useUser from "Hooks/useUser";
import { Redirect } from "react-router-dom";
import { Row, Col, Card, Layout, Input, Button, Form } from "antd";

import("./style.scss");

const { Content } = Layout;

export default function LoginPage() {
  const { login, user, auth } = useUser();

  if (user) return <Redirect to="/merchants" />;
  return (
    <div className="login-page">
      <Layout className="login-page">
        <Content>
          <Row align="middle" style={{ minHeight: "100vh" }} justify="center">
            <Col sm={6}>
              <Card style={{ padding: "20px 10px" }}>
                <Form onFinish={login} autoComplete="off" layout="vertical">
                  <Logo style={{ marginBottom: 30, width: 128 }} />
                  <h2>Anomaly Dashboard</h2>
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      block
                      style={{ marginTop: 10, height: 40 }}
                      htmlType="submit"
                      disabled={auth.isLoading}
                      loading={auth.isLoading}
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
}
