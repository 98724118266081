import React from "react";
import axios from "Clients/axios";
import { useQuery } from "react-query";
import { PageHeader } from "antd";
import MerchantsSummary from "Components/MerchantsSummary";
import PageLoading from "Components/PageLoading";
import useUI from "Hooks/useUI";

export default function MerchantsSummaryPage() {
  const {
    data: { region },
  } = useUI();
  const merchants = useQuery(["merchants-summary", region], () => {
    return axios.get("/anomaly-dashboard/merchants/summary", {
      params: { region },
    });
  });
  if (merchants.isLoading) return <PageLoading />;

  return (
    <>
      <PageHeader
        title="Merchants Summary"
        subTitle="Summary for all merchants"
      />
      <MerchantsSummary merchants={merchants} />
    </>
  );
}
